.dragdrop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dragdrop h1 {
  margin-top: 10px;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}
.dragdrop h2 {
  margin-top: 5px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}
.dragdrop-icon {
  font-size: 40px;
}
.dropzone-container {
  width: 60%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropzone-image {
  width: 60%;
  height: 200px;
  border-radius: 10px;
  object-fit: contain;
}
